import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { graphql } from 'gatsby'
import { DateTime } from 'luxon'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'
import AuthorCard from '@c/author-card'
import ShareBox from '@c/share-box'
import RedLine from '@c/red-line'
import Article from '@m/Article'
import makeSlug from '@u/make-slug'
import ResourceAuthorCard from '@c/resource-author-card'

import './detail.css'
import { scrollIntoView } from '../../../utils/scroll-into-view'
import useForceBlank from '../../../hooks/use-force-blank'
import RelatedResources from '@/components/related-resources'
import Slider from '@c/slider-flickity'
import RelatedResourceCard from '@/components/related-resource-card'
import useCodeHighlighter from '@/hooks/use-code-highlighter'
import ProjectImage from '@/components/project-image'

const Page = ({ data }) => {
  const { article, pageSettings, relatedPosts } = data
  const {
    resourcesTitle,
    resourcesSlug,
    resourcesArticleTitle,
    resourcesArticleSlug,
    resourcesArticleRelatedTopTitle,
    resourcesArticleRelatedTitle,
    categorySlug
  } = pageSettings
  const model = new Article(article)
  const parentPages = [
    {
      title: resourcesTitle,
      url: makeSlug.generic(article.locale, resourcesSlug)
    },
    {
      title: resourcesArticleTitle,
      url: makeSlug.generic(article.locale, resourcesSlug, resourcesArticleSlug)
    }
  ]
  const title = article.title
  const url = `https://zeo.org${model.getPath(pageSettings)}`
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}`
  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    title
  )}&url=${encodeURIComponent(url)}`
  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    url
  )}`
  const whatsappLink = `whatsapp://send?text=${encodeURIComponent(url)}`
  const mailLink = `mailto:?subject=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(url)}`
  let observer = null
  const [activeIndexElem, setActiveIndexElem] = useState(null)
  const content = useForceBlank(article.content)

  useEffect(() => {
    observer = new IntersectionObserver(entries => {
      let activeElem = null

      entries.forEach(entry => {
        if (entry.intersectionRatio <= 0) {
          return
        }
        activeElem = entry.target.id
      })

      if (activeElem) {
        setActiveIndexElem(activeElem)
      }
    })
  }, [])
  useEffect(() => {
    const elems = article.index
      .map(section => {
        return document.getElementById(section.sectionId)
      })
      .filter(elem => !!elem)

    elems.forEach(elem => observer.observe(elem))

    return () => elems.forEach(elem => observer.unobserve(elem))
  }, [observer, article.index])
  useCodeHighlighter(content)
  return (
    <Layout
      locale={article.locale}
      seo={{ ...model.getSeoComponentProps(pageSettings) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <Breadcrumb
              className="resource-detail-breadcrumb"
              parentPages={parentPages}
              currentPage={title}
            />
          </div>
          <div className="col-12 col-lg-6 d-flex flex-wrap align-items-center justify-content-end mt-32 mt-lg-0 ml-auto">
            {/* author */}
            <AuthorCard
              photo={article.author?.profileImage}
              name={`${article.author?.firstName} ${article.author?.lastName}`}
              nickname={article.author?.nickname}
              categoryBase={makeSlug.generic(
                article.locale,
                resourcesSlug,
                resourcesArticleSlug,
                categorySlug
              )}
              categories={article.category ? [article.category] : []}
              locale={article.locale}
            />
            <p className="fs-small ml-32">
              {DateTime.fromJSDate(
                new Date(article?.meta?.firstPublishedAt)
              ).toFormat('dd MMM yyyy', { locale: article.locale })}
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="blog-post container px-0">
              {/* header */}
              <header className="blog-post-header d-flex flex-column align-items-start justify-content-center">
                <div className="col-12 mb-40">
                  {article.image && <ProjectImage image={article.image} />}
                </div>
              </header>
              {/* body */}
              <div className="blog-post-body my-40">
                <div className="d-flex flex-row align-items-start">
                  <div className="col-2 d-none d-lg-block pl-32 u-sticky">
                    <RedLine />
                    {/* contents */}
                    <div className="jumplink-container">
                      {article.index.map((interPageLink, index) => {
                        return (
                          <div
                            className={cn(
                              'content-jumplink',
                              activeIndexElem === interPageLink.sectionId &&
                              'content-jumplink-active'
                            )}
                            key={index}
                          >
                            <div
                              className="pt-16"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                scrollIntoView(interPageLink.sectionId)
                              }
                            >
                              {interPageLink.title}
                            </div>
                          </div>
                        )
                      })}

                      <div
                        className={'content-jumplink content-jumplink-newsletter'}
                      >
                        <div
                          className="pt-16"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            scrollIntoView('#footerForm')
                          }
                        >
                          {article.locale === 'en' ? 'Subscribe to Newsletter' : 'Bültene Kaydol'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="col-12 pl-32">
                      {/* title */}
                      <PageHeader title={article.title} />
                      <div className="d-block d-lg-none">
                        {/* sharebox */}
                        <ShareBox
                          className="d-flex flex-row"
                          facebookLink={facebookLink}
                          twitterLink={twitterLink}
                          mailLink={mailLink}
                          whatsappLink={whatsappLink}
                          linkedinLink={linkedinLink}
                        />
                      </div>
                    </div>
                    <div
                      className="blog-post-content mt-80 pl-32"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </div>
                  <div className="col-2 d-none d-lg-flex justify-content-end u-sticky">
                    {/* sharebox */}
                    <ShareBox
                      className="d-flex flex-column pr-32"
                      facebookLink={facebookLink}
                      twitterLink={twitterLink}
                      mailLink={mailLink}
                      whatsappLink={whatsappLink}
                      linkedinLink={linkedinLink}
                    />
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <ResourceAuthorCard
        locale={article.locale}
        author={article.author}
        nickname={article.author?.nickname}
        photo={article.author?.profileImage}
      />
      {!!relatedPosts?.nodes?.length && (
        <div className="container mt-40">
          <RelatedResources
            topTitle={resourcesArticleRelatedTopTitle}
            title={resourcesArticleRelatedTitle}
          >
            <Slider
              className="mt-20 ml-n16"
              options={{
                draggable: true,
                pageDots: true
              }}
            >
              {relatedPosts.nodes.map((item, index) => (
                <div key={index} className="col-12 col-lg-6 my-16">
                  <RelatedResourceCard
                    title={item.title}
                    description={item.descriptionSmall}
                    thumb={item.image}
                    postUrl={makeSlug.generic(
                      item.locale,
                      resourcesSlug,
                      resourcesArticleSlug,
                      item.slug
                    )}
                    category={item.category}
                    categoryUrl={makeSlug.generic(
                      item.locale,
                      resourcesSlug,
                      resourcesArticleSlug,
                      categorySlug,
                      item?.category?.slug
                    )}
                    locale={item.locale}
                    author={item.author}
                  />
                </div>
              ))}
            </Slider>
          </RelatedResources>
        </div>
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ArticleDetailQuery(
    $locale: String
    $articleId: String
    $categoryId: String
  ) {
    article: datoCmsArticle(id: { eq: $articleId }) {
      _allSlugLocales {
        locale
        value
      }
      locale
      title
      slug
      content
      meta {firstPublishedAt}
      category {
        id
        slug
        title
      }
      image {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      index {
        title
        sectionId
      }
      author {
        firstName
        lastName
        nickname
        jobPosition
        profileImage {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(width: 128)
              }
            }
          }
        }
      }
      metaTags {
        ...MetaTags
      }
    }

    relatedPosts: allDatoCmsArticle(
      filter: {
        category: { id: { eq: $categoryId } }
        slug: { regex: "/.+/" }
        id: { ne: $articleId }
        locale: {eq: $locale}
      }
      sort: { fields: [meta___firstPublishedAt], order: DESC }
      limit: 12
    ) {
      nodes {
        locale
        title
        slug
        descriptionSmall
        category {
          id
          slug
          title
        }
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 120)
              }
            }
          }
        }

        author {
          firstName
          lastName
          nickname
          jobPosition
          profileImage {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 128)
                }
              }
            }
          }
        }
      }
    }

    # page settings
    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      _allResourcesArticleSlugLocales {
        locale
        value
      }
      resourcesTitle
      resourcesSlug
      resourcesArticleTitle
      resourcesArticleRelatedTopTitle
      resourcesArticleRelatedTitle
      resourcesArticleSlug

      categorySlug
    }
  }
`
