import React from 'react'

import './index.css'
import { AuthorImage } from '../author-avatar'
import GatsbyLink from 'gatsby-link'
import ProjectImage from '../project-image'

const RelatedResourceCard = ({
  title,
  description,
  thumb,
  author,
  locale,
  postUrl,
  category,
  categoryUrl
}) => {
  return (
    <article className="related-resource">
      {thumb && (
        <ProjectImage className="mb-16" objectFit={'contain'} image={thumb} />
      )}
      <GatsbyLink className="card-link" to={postUrl}>
        <h4 className="mb-16">{title}</h4>
      </GatsbyLink>

      {description && <p>{description}</p>}
      {author && (
        <div className="row no-gutters flex-nowrap align-items-center">
          <div className="col-auto pr-4">
            <AuthorImage
              author={author}
              locale={locale}
              photo={author?.profileImage}
              small
              nickname={author?.nickname}
            >
              {author.firstName + ' ' + author.lastName}
            </AuthorImage>
          </div>
          <div className="col">
            {category && categoryUrl && (
              <span>
                ,
                <strong className="ml-4">
                  <GatsbyLink to={categoryUrl}>{category.title}</GatsbyLink>
                </strong>
              </span>
            )}
          </div>
        </div>
      )}
    </article>
  )
}

export default RelatedResourceCard
