import React from 'react'
import PropTypes from 'prop-types'

import './index.css'
import RedLine from '@/components/red-line'

const RelatedResources = ({ title, topTitle, children }) => (
  <section className="related-resources">
    {(topTitle || title) && (
      <div>
        {topTitle && (
          <h5 className="ff-body fw-text-normal lh-head-flat mb-4">
            {topTitle}
          </h5>
        )}
        {title && <h2 className="lh-head-flat">{title}</h2>}
        <RedLine className="my-24" />
      </div>
    )}
    {children}
  </section>
)

RelatedResources.defaultValues = {
  title: '',
  topTitle: ''
}
RelatedResources.propTypes = {
  title: PropTypes.string,
  topTitle: PropTypes.string
}

export default RelatedResources
